// @flow

import * as React from 'react'
import styled from 'styled-components'
import StyledModal, { ModalProvider } from 'styled-react-modal'
import CloseSvg from '../Icons/CloseSvg'
import { Colors } from '../Colors'
import { RobotoBold, RobotoMedium } from '../Typography/Fonts'
import BluredModalBackground from './BluredModalBackground'
import { device } from '../Devices'

const ModalContainer = StyledModal.styled`
  position: relative;
  display: flex;
  overflow: auto;
  background-color: ${({ noBackground }) =>
    noBackground
      ? 'transparent'
      : process.env.REACT_APP_LIGHT_UI === 'true'
      ? Colors.ghostWhite
      : Colors.darkCharcoal};
  border-top-right-radius: ${({ noBackground }) =>
    noBackground ? '32px' : '16px'};
  border-top-left-radius: ${({ noBackground }) =>
    noBackground ? '32px' : '16px'};
  border-bottom-right-radius: ${({ fitContent, noBackground }) =>
    noBackground ? '32px' : fitContent ? '16px' : '0'};
  border-bottom-left-radius: ${({ fitContent, noBackground }) =>
    noBackground ? '32px' : fitContent ? '16px' : '0'};
  box-shadow: ${({ noBackground }) =>
    noBackground
      ? `0 0 16px 0 ${Colors.black85}`
      : process.env.REACT_APP_LIGHT_UI === 'true'
      ? `0 0 16px 0 ${Colors.black85}`
      : `0 0 16px 0 ${Colors.black50}, inset 0 1px 0 0 ${Colors.white10}`};
  width: ${({ fitContent }) => (fitContent ? 'min-content' : '100%')};
  height: ${({ fitContent, config }) =>
    fitContent
      ? 'max-content'
      : config
      ? 'calc(100% - 40px)'
      : 'calc(100% - 80px)'};
  align-self: ${({ fitContent }) => (fitContent ? 'center' : 'flex-end')};
  transform: ${({ opacity }) => (opacity === 0 ? 'translateY(100%)' : 'none')};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: ${({ opacity }) => opacity};
  margin: ${({ noBackground }) => (noBackground ? '16px' : '0')};

  @media ${device.laptop} {
    transform: none;
    transition: opacity 0.3s ease-in-out;
    align-self: center;
    border-radius: ${({ fullScreen, noBackground }) =>
      noBackground ? '32px' : fullScreen ? 0 : '16px'};
    width: ${({ fullScreen }) => (fullScreen ? '100%' : 'min-content')};
    height: ${({ fullScreen }) => (fullScreen ? '100%' : 'max-content')};
    max-height: ${({ fullScreen }) =>
      fullScreen ? '100%' : 'calc(100% - 32px)'};
    margin: ${({ fullScreen }) => (fullScreen ? 0 : '16px')};
  }
`

export const ModalTitle: any = styled.h1`
  margin-top: 0;
  margin-bottom: 20px;
  font-family: ${RobotoBold.fontFamily};
  font-size: 36px;
  font-weight: ${RobotoBold.weight};
  line-height: 1;
  text-align: center;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black85
    : Colors.white85};
`

export const MainTitle: any = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoMedium.weight};
  line-height: 20px;
  z-index: 5;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black85
    : Colors.white85};
  padding: 18px 0;
  text-align: center;
  backdrop-filter: blur(4px);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? `0 1px 0 0 ${Colors.white100}, inset 0 -1px 0 0 ${Colors.black10}`
    : `0 1px 0 0 rgba(255, 255, 255, 0.03), inset 0 -1px 0 0 ${Colors.black15}, inset 0 1px 0 0 ${Colors.white10}`};
  background-color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.ghostWhite75
    : Colors.arsenic90};

  @media ${device.laptop} {
    border-top-left-radius: ${({ isFilter }) => (isFilter ? 0 : '16px')};
    border-top-right-radius: ${({ isFilter }) => (isFilter ? 0 : '16px')};
  }
`

export const ModalDefaultContent: any = styled.div`
  margin: auto;
  padding: 48px 30px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black60
    : Colors.white60};
  width: 360px;
`

export const CloseButton: any = styled.button`
  position: fixed;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 6;

  &:focus {
    outline: none;
  }

  path {
    fill: ${process.env.REACT_APP_LIGHT_UI === 'true'
      ? Colors.black100
      : Colors.white100};
    fill-opacity: 0.85;
    transition: fill 0.15s ease-in-out, fill-opacity 0.15s ease-in-out;
  }

  &:hover {
    path {
      fill: ${Colors.brand};
      fill-opacity: 1;
    }
  }
`

const WrapperClose = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
`

type Props = {|
  isOpen: boolean,
  onRequestClose?: () => void,
  children: React.Node,
  fullScreen?: boolean,
  closable?: boolean,
  fitContent?: boolean,
  id?: string,
  config?: boolean,
  noBackground?: boolean,
|}

type State = {|
  opacity: number,
|}

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      opacity: 0,
    }
  }

  afterOpen = () => {
    setTimeout(() => {
      this.setState({ opacity: 1 })
    }, 100)
  }

  beforeClose = (): Promise<void> => {
    return new Promise(resolve => {
      this.setState({ opacity: 0 })
      setTimeout(resolve, 800)
    })
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      children,
      fullScreen,
      closable,
      fitContent,
      id,
      config,
      noBackground,
    } = this.props
    const { opacity } = this.state

    return (
      <div id={id}>
        <ModalProvider backgroundComponent={BluredModalBackground}>
          <ModalContainer
            opacity={opacity}
            isOpen={isOpen}
            fullScreen={fullScreen}
            fitContent={fitContent}
            afterOpen={this.afterOpen}
            beforeClose={this.beforeClose}
            onBackgroundClick={() => onRequestClose && onRequestClose()}
            onEscapeKeydown={() => onRequestClose && onRequestClose()}
            backgroundProps={{ opacity, fullScreen, isOpen, noBackground }}
            config={config}
            noBackground={noBackground}
          >
            {onRequestClose && closable && (
              <WrapperClose>
                <CloseButton
                  onClick={() => onRequestClose()}
                  ariaLabel="Fermer"
                >
                  <CloseSvg />
                </CloseButton>
              </WrapperClose>
            )}

            {children}
          </ModalContainer>
        </ModalProvider>
      </div>
    )
  }
}

export default (Modal: React.ComponentType<Props>)
