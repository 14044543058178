// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoMedium } from './Typography/Fonts'
import RotateDeviceSvg from './Icons/RotateDeviceSvg'
import { Colors } from './Colors'
import { useTranslation } from 'react-i18next'
import FooterCusthome from './FooterCusthome'

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.ghostWhite
    : Colors.darkCharcoal};
  z-index: 2000;
`

const Description = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.weight};
  line-height: 14px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black85
    : Colors.white85};
  margin-top: 10px;
  max-width: 256px;
  text-align: center;
`

export const RotateDeviceScreen = (): React.Node => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <RotateDeviceSvg />
      <Description>{t('rotateDevice')}</Description>
      <FooterCusthome />
    </Wrapper>
  )
}

type WINDOW_SIZES = {|
  innerHeight: number,
  innerWidth: number,
  outerHeight: number,
  outerWidth: number,
|}

const canRotate = () => typeof window.orientation !== 'undefined'

function getSize(): WINDOW_SIZES {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  }
}

const useWindowSize = (): WINDOW_SIZES => {
  const [windowSize, setWindowSize] = React.useState(getSize())

  const handleResize = event => {
    event.preventDefault()
    setWindowSize(getSize())
    event.stopPropagation()
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

const RotateDeviceScreenOnlyOnSmallLanscapeOrientatedScreen = (): React.Node => {
  const { innerHeight, innerWidth } = useWindowSize()

  const isLandscape = innerHeight < innerWidth
  const isSmallHeight = innerHeight < 600

  if (canRotate() && isLandscape && isSmallHeight) {
    return <RotateDeviceScreen />
  }

  return null
}

export default RotateDeviceScreenOnlyOnSmallLanscapeOrientatedScreen
