// @flow

import * as React from 'react'

export default (): React.Element<'svg'> => (
  <svg
    width="258"
    height="102"
    viewBox="0 0 258 102"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.299 60.19h-7.59V8.567c0-4.19-1.355-5.541-5.556-5.541H54.124c-3.93 0-5.42.81-7.995 3.919L3.304 59.378C1.27 61.676 1 62.757 1 66.541v6.756c0 4.054 1.355 5.54 5.556 5.54H49.11v14.595c0 4.19 1.355 5.541 5.556 5.541h9.487c4.201 0 5.556-1.351 5.556-5.54V78.837h7.59c4.2 0 5.556-1.487 5.556-5.54V65.73c0-4.054-1.355-5.54-5.556-5.54zM49.11 31.675v28.513H25.665L49.11 31.676zm43.096 2.973V67.08c0 20.81 13.552 33.919 37.133 33.919 23.445 0 36.862-13.108 36.862-33.919V34.65C166.2 14.109 152.784 1 129.339 1c-23.58 0-37.133 13.108-37.133 33.649zm52.04-.27v33.107c0 8.65-5.556 13.379-14.907 13.379-9.622 0-15.179-4.73-15.179-13.379V34.378c0-8.648 5.557-13.243 15.179-13.243 9.35 0 14.907 4.595 14.907 13.243zm107.198 25.81h-7.59V8.568c0-4.19-1.355-5.541-5.556-5.541h-10.029c-3.93 0-5.42.81-7.995 3.919l-42.825 52.432c-2.033 2.298-2.304 3.379-2.304 7.163v6.756c0 4.054 1.355 5.54 5.556 5.54h42.554v14.595c0 4.19 1.355 5.541 5.557 5.541h9.486c4.201 0 5.556-1.351 5.556-5.54V78.837h7.59c4.2 0 5.556-1.487 5.556-5.54V65.73c0-4.054-1.355-5.54-5.556-5.54zm-28.189-28.513v28.513H199.81l23.445-28.513z"
      fill="#000"
      fillRule="nonzero"
      stroke={process.env.REACT_APP_LIGHT_UI === 'true' ? '#000' : '#fff'}
      fillOpacity={process.env.REACT_APP_LIGHT_UI === 'true' ? '0.05' : '0.1'}
      strokeDasharray="1,3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".6"
    />
  </svg>
)
