// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './../Colors'
import { BaseButton, IconInTheButton, type BaseButtonProps } from './BaseButton'

const StyledDefaultButton = styled(BaseButton)`
  color: ${({ forceDark }) =>
    process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
      ? Colors.black85
      : Colors.white85};
  border-color: ${({ forceDark }) =>
    process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
      ? Colors.black85
      : Colors.white85};
  background-color: transparent;

  &:hover {
    color: ${({ forceDark }) =>
      process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
        ? Colors.white85
        : Colors.black85};
    border-color: transparent;
    background-color: ${({ forceDark }) =>
      process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
        ? Colors.black85
        : Colors.white85};
  }

  &:disabled {
    color: ${({ forceDark }) =>
      process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
        ? Colors.white30
        : Colors.black30};
    border-color: ${({ forceDark }) =>
      process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
        ? Colors.white30
        : Colors.black30};
    background-color: transparent;
  }

  & ${IconInTheButton} path {
    fill: ${({ forceDark }) =>
      process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
        ? Colors.black85
        : Colors.white85};
    transition: fill 0.25s ease-in-out;
  }

  &:hover ${IconInTheButton} path {
    fill: ${({ forceDark }) =>
      process.env.REACT_APP_LIGHT_UI === 'true' && !forceDark
        ? Colors.white85
        : Colors.black85};
  }
`

export const DefaultButton = ({
  children,
  ...otherProps
}: {
  ...BaseButtonProps,
}): React.Node => (
  <StyledDefaultButton {...otherProps}>{children}</StyledDefaultButton>
)
