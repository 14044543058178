// @flow

import * as React from 'react'

export default (): React.Element<'svg'> => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 0C21.515 0 0 21.515 0 48s21.515 48 48 48 48-21.515 48-48S74.485 0 48 0zm0 4.174c16.67 0 31.15 9.276 38.56 22.956H48c-.079 0-.158.004-.236.013-10.744.121-19.566 8.41-20.536 18.933l-14.633-23.85C20.56 11.299 33.422 4.175 48 4.175zM10.068 26.09l20.144 32.825a2.087 2.087 0 0 0 .245.338C34.174 65.027 40.643 68.87 48 68.87c2.949 0 5.752-.623 8.299-1.733L42.408 91.46C20.828 88.716 4.174 70.333 4.174 48c0-7.994 2.165-15.461 5.894-21.909zM48 31.304c9.245 0 16.696 7.45 16.696 16.696a16.651 16.651 0 0 1-2.124 8.156c-.026.04-.05.08-.073.123-2.871 5.03-8.273 8.417-14.499 8.417A16.664 16.664 0 0 1 31.304 48c0-9.245 7.45-16.696 16.696-16.696zm12.465 0h28.068A43.753 43.753 0 0 1 91.826 48c0 24.23-19.597 43.826-43.826 43.826-.33 0-.655-.017-.982-.024l19.104-33.453c.046-.079.087-.16.123-.244A20.72 20.72 0 0 0 68.87 48c0-6.827-3.316-12.885-8.405-16.696z"
      fill={process.env.REACT_APP_LIGHT_UI === 'true' ? '#000' : '#fff'}
      fillRule="evenodd"
      fillOpacity=".3"
    />
  </svg>
)
