// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './Colors'
import { RobotoMedium } from './Typography/Fonts'
import ChromeSvg from './Icons/ChromeSvg'
import FooterCusthome from './FooterCusthome'
import { PrimaryButton } from './Button/index'

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.ghostWhite
    : Colors.darkCharcoal};
  z-index: 200;
`

const Description = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoMedium.weight};
  line-height: 20px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black85
    : Colors.white85};
  margin-top: 20px;
  max-width: 256px;
  text-align: center;
  margin-bottom: 40px;
`

export default (): React.Node => (
  <Wrapper>
    <ChromeSvg />
    <Description>Un navigateur moderne est nécessaire !</Description>
    <PrimaryButton
      onClick={() =>
        window.open(
          'https://www.google.com/chrome',
          '_blank',
          'nofollow,noopener,noreferrer',
        )
      }
    >
      Télécharger
    </PrimaryButton>
    <FooterCusthome />
  </Wrapper>
)
