// @flow

import type { Element } from 'react'
import React from 'react'
import { Colors } from '../Colors'

const CheckGreenSvg = (): Element<'svg'> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <path
      fill={Colors.brand}
      fillRule="nonzero"
      d="M40 6.667C58.38 6.667 73.333 21.62 73.333 40S58.38 73.333 40 73.333 6.667 58.38 6.667 40 21.62 6.667 40 6.667zM40 0C17.91 0 0 17.91 0 40s17.91 40 40 40 40-17.91 40-40S62.09 0 40 0zm14.643 25l-18.81 19.28-8.813-8.353-6.187 6.193 15 14.547 25-25.477-6.19-6.19z"
    />
  </svg>
)

export default CheckGreenSvg
