// @flow

import * as React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { initErrorReportingAgent } from './ErrorReporting'
import MainApplication from './MainApplication'
import history from './history'
import BrowserSupportHoc from './BrowserSupportHoc'

const sentryDSN = process.env.REACT_APP_SENTRY_DSN
const buildEnvironment = process.env.REACT_APP_BUILD_ENVIRONMENT
if (sentryDSN && buildEnvironment) {
  initErrorReportingAgent(sentryDSN, buildEnvironment)
}

const root = document.getElementById('root')
if (!root) {
  throw new Error('Unable to find the root element to mount the application')
}

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
if (!apiEndpoint) {
  throw new Error('The `REACT_APP_API_ENDPOINT` environment variable is empty')
}

const programHostname = process.env.REACT_APP_PROGRAM_HOSTNAME
if (!programHostname) {
  throw new Error(
    'The `REACT_APP_PROGRAM_HOSTNAME` environment variable is empty',
  )
}

const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID
if (!oauthClientId) {
  throw new Error(
    'The `REACT_APP_OAUTH_CLIENT_ID` environment variable is empty',
  )
}

const oauthClientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET
if (!oauthClientSecret) {
  throw new Error(
    'The `REACT_APP_OAUTH_CLIENT_SECRET` environment variable is empty',
  )
}

ReactDOM.render(
  <BrowserSupportHoc>
    <Router history={history}>
      <MainApplication
        apiEndpoint={apiEndpoint}
        programHostname={programHostname}
        oauthClientId={oauthClientId}
        oauthClientSecret={oauthClientSecret}
      />
    </Router>
  </BrowserSupportHoc>,
  root,
)
