// @flow

import type { Element } from 'react'
import React from 'react'

const CloseSvg = (): Element<'svg'> => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.228 0C17.692.05 20 2.549 20 10.54 20 17.647 17.53 20 10 20 2.41 20 0 17.59 0 10v-.226C.05 2.362 2.487 0 10 0zM10 1.668l-.394.002-.193.002-.376.009-.184.005-.36.015c-.235.012-.463.026-.684.044l-.325.03c-3.84.382-5.328 1.87-5.71 5.71l-.03.325c-.018.221-.032.45-.044.685l-.015.359-.005.184-.009.376-.002.193-.002.394.002.394.002.193.009.376.005.184.015.36c.012.235.026.463.044.684l.03.325c.382 3.84 1.87 5.328 5.71 5.71l.325.03c.221.018.45.032.685.044l.359.015.184.005.376.009.193.002.394.002.392-.002c.128-.001.255-.003.38-.006l.368-.01.18-.007.352-.016c.173-.01.342-.02.507-.034l.324-.028.158-.016.308-.036c3.33-.42 4.756-1.764 5.2-4.872l.038-.287c.012-.098.023-.197.033-.298l.027-.307.012-.157.02-.323c.012-.218.02-.444.026-.676l.006-.354.002-.365-.002-.423a38.854 38.854 0 0 0-.006-.411l-.01-.398-.007-.194-.017-.38-.021-.366-.013-.179-.029-.348c-.403-4.399-2.056-5.87-6.538-6.13l-.355-.017-.367-.013-.187-.005-.385-.006L10 1.667zm2.88 5.452a.833.833 0 0 1 .05 1.126l-.05.053L11.18 10l1.702 1.702a.833.833 0 0 1-1.126 1.228l-.053-.05L10 11.18 8.298 12.88a.833.833 0 0 1-1.228-1.126l.05-.053L8.82 10 7.12 8.298a.833.833 0 0 1 1.07-1.27l.056.042.053.05L10 8.82l1.702-1.702a.833.833 0 0 1 1.179 0z"
      fill="#000"
      fillRule="nonzero"
      fillOpacity=".85"
    />
  </svg>
)

export default CloseSvg
