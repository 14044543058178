// @flow

import type { Node } from 'react'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import Color from 'color'
import { Colors } from '../../../../GuidelinesNew/Colors'

const blink = keyframes`
  0% {
    background-color: ${Color(Colors.black100).rgb().alpha(0.2).string()}
  }
  20% {
    background-color: ${Color(Colors.brand).rgb().alpha(1).string()}
  }
  100% {
    background-color: ${Color(Colors.black100).rgb().alpha(0.2).string()}
  }
`

const Dot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 3px 0px 0px;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.05);
  background-color: ${({ animated }) => (animated ? 'none' : Colors.brand)};

  &:last-child {
    margin: 0;
  }
`

const DotContainer = styled.span`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 24px;
    height: min-content;
    padding: 3px 3px;
  }

  ${Dot} {
    animation-name: ${({ animated }) => (animated ? blink : 'none')};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  ${Dot}:nth-child(1) {
    animation-delay: 0s;
  }

  ${Dot}:nth-child(2) {
    animation-delay: 0.2s;
  }

  ${Dot}:nth-child(3) {
    animation-delay: 0.4s;
  }
`

export default ({
  animated = true,
  className,
}: {|
  animated?: boolean,
  className?: string,
|}): Node => (
  <DotContainer animated={animated} className={className}>
    <Dot />
    <Dot />
    <Dot />
  </DotContainer>
)
