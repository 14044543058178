// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoBlack, RobotoNormal } from './Typography/Fonts'
import { Colors } from './Colors'

const Wrapper = styled.div`
  display: ${process.env.REACT_APP_DISABLE_WATERMARK === 'true'
    ? 'none'
    : 'block'};
  cursor: pointer;
  position: absolute;
  width: max-content;
  right: 0;
  left: 0;
  bottom: 10px;
  margin: auto;
  font-family: ${RobotoBlack.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoBlack.weight};
  line-height: 15px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black100
    : Colors.white100};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  pointer-events: ${({ noClick }) => (noClick ? 'none' : 'initial')};
  z-index: 1;

  span {
    font-family: ${RobotoNormal.fontFamily};
    font-weight: ${RobotoNormal.weight};
  }

  &:hover {
    opacity: 1;
  }
`

export default ({ noClick }: { noClick?: boolean }): React.Node => {
  return (
    <Wrapper
      noClick={noClick}
      onClick={() => window.open('https://custhome.app', '_blank')}
    >
      <span>Made by </span>Custhome
    </Wrapper>
  )
}
