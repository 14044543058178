// @flow

import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import { PrimaryButton } from '../Button'
import IconCheckmarkBase from './../Icons/icon-checkmark-base.svg'
import IconCheckmarkHover from './../Icons/icon-checkmark-hover.svg'
import IconCheckmarkChecked from './../Icons/icon-checkmark-checked.svg'

export const Input: any = styled.input`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.weight};
  line-height: 16px;
  background-color: transparent;
  border: 0;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black85
    : Colors.white85};

  &::placeholder {
    color: ${process.env.REACT_APP_LIGHT_UI === 'true'
      ? Colors.black30
      : Colors.white30};
  }

  &:first-child {
    margin-top: 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 4px 1000px
      ${process.env.REACT_APP_LIGHT_UI === 'true'
        ? Colors.ghostWhite
        : Colors.darkCharcoal}
      inset;
    -webkit-text-fill-color: ${process.env.REACT_APP_LIGHT_UI === 'true'
      ? Colors.black85
      : Colors.white85} !important;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${Colors.black30};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const CheckboxInput: any = styled.input.attrs({ type: 'checkbox' })`
  @supports (-webkit-appearance: none) {
    -webkit-appearance: none;

    width: 20px;
    height: 20px;
    margin: 0 14px;
    border: none;

    background: url('${IconCheckmarkBase}') no-repeat center center;
    cursor: pointer;

    &:hover {
      background-image: url('${IconCheckmarkHover}');
    }

    &:focus {
      filter: drop-shadow(0px 0px 5px ${Colors.white30});
    }

    &:checked {
      background-image: url('${IconCheckmarkChecked}');
    }

    /* Prefectch the background image to avoid a blink */
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      z-index: -1;
      content: url('${IconCheckmarkHover}') url('${IconCheckmarkChecked}');
    }
  }
`

export const SubmitButton: any = styled(PrimaryButton).attrs({
  type: 'submit',
})`
  margin-top: 10px;
`

export const SubmitButtonContainer: any = styled.div`
  width: 175px;
  margin: 40px auto 0 auto;

  & ${SubmitButton} {
    margin: 0;
  }
`

export const Form: any = styled.form``

export const ErrorMessage: any = styled.div`
  padding: 20px 0 0 0;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.weight};
  font-size: 12px;
  color: ${Colors.red};
`
