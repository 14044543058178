// @flow

import * as React from 'react'
import { type Axios } from 'axios'
import ClientOAuth2 from 'client-oauth2'
import Customer from '../CustomerArea/Customer'
import { fetchMe, requestNewPassword } from '../CustomerArea/api'
import {
  readToken,
  writeToken,
  deleteToken,
  writeShoppingCart,
} from '../CustomerArea/storage'
import LoadingScreen from '../GuidelinesNew/LoadingScreen'
import CustomerContext from './Context'
import { CustomerContextApi } from './CustomerContextApi'
import ReactCustomerContextApi from './ReactCustomerContextApi'
import CustomerConnectionModal, {
  REGISTRATION_MODE,
  LOGIN_MODE,
} from '../CustomerArea/Guidelines/CustomerConnectionModal'
import { attachCustomerToContext } from '../ErrorReporting'
import CustomerAuthenticationExpired from '../CustomerArea/CustomerAuthenticationExpired'
import CustomerAuthenticationMissing from '../CustomerArea/CustomerAuthenticationMissing'
import history from '../history'
import { programPreviewHomePageRoute } from '../urls'
import ConfigurationShoppingCart from '../FlatConfigurator/MainMenu/Configuration/ShoppingCart/ConfigurationShoppingCart'

type Props = {|
  httpClient: Axios,
  authClient: typeof ClientOAuth2,
  programId: string,
  privacyPoliciesPageUrl: ?string,
  termsOfServicesPageUrl: ?string,
  children: React.Node,
|}

type State = {|
  customerContextApi: CustomerContextApi,
  initialisingAuthentication: boolean,
  isConnectionCancelable: boolean,
  isConnectionModalOpen: boolean,
  defaultMode: typeof REGISTRATION_MODE | typeof LOGIN_MODE,
  currentConnectionProcessCallback: ?(
    customer: ?Customer,
  ) => Promise<?Customer>,
  shoppingCart: ?ConfigurationShoppingCart,
|}

class CustomerAreaBridge extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const customerContextApi = new ReactCustomerContextApi(
      this.props.httpClient,
      this.props.authClient,
      this.onCustomerUpdated,
      this.openCustomerRegistrationModal,
      this.openCustomerLogInModal,
      this.logOutCustormer,
      null,
    )

    attachCustomerToContext(customerContextApi.customer)

    this.state = {
      customerContextApi,
      defaultMode: REGISTRATION_MODE,
      initialisingAuthentication: true,
      isConnectionCancelable: true,
      isConnectionModalOpen: false,
      currentConnectionProcessCallback: null,
      shoppingCart: null,
    }
  }

  componentDidMount() {
    const { httpClient, authClient } = this.props
    const { customerContextApi } = this.state

    const storedToken = readToken(authClient) || null
    if (!storedToken) {
      this.setState({
        customerContextApi: customerContextApi.replaceCustomer(null),
        initialisingAuthentication: false,
      })
      return
    }

    this.setState({ initialisingAuthentication: true }, async () => {
      try {
        const { me, token } = await fetchMe(httpClient, storedToken)
        if (!me || !token) {
          throw new CustomerAuthenticationMissing()
        }

        writeToken(token)

        const customer = new Customer(
          token,
          me.id,
          me.email,
          me.firstName,
          me.lastName,
          me.phone,
        )

        this.setState({
          customerContextApi: customerContextApi.replaceCustomer(customer),
          initialisingAuthentication: false,
        })
      } catch (err) {
        if (
          err instanceof CustomerAuthenticationMissing ||
          err instanceof CustomerAuthenticationExpired
        ) {
          deleteToken()

          this.setState({
            customerContextApi: customerContextApi.replaceCustomer(null),
            initialisingAuthentication: false,
          })

          return
        }

        throw err
      }
    })
  }

  onCustomerUpdated = async (newCustomer: Customer) => {
    const { customerContextApi } = this.state

    return new Promise(resolve => {
      this.setState(
        { customerContextApi: customerContextApi.replaceCustomer(newCustomer) },
        () => resolve(),
      )
    })
  }

  openCustomerRegistrationModal = async (
    shoppingCart?: ConfigurationShoppingCart,
  ): Promise<?Customer> => {
    return new Promise(resolve => {
      this.setState({
        isConnectionModalOpen: true,
        defaultMode: REGISTRATION_MODE,
        shoppingCart: shoppingCart || null,
        currentConnectionProcessCallback: async (customer: ?Customer) =>
          Promise.resolve(resolve(customer || null)),
      })
    })
  }

  openCustomerLogInModal = async (cancelable: boolean): Promise<?Customer> => {
    return new Promise(resolve => {
      this.setState({
        isConnectionModalOpen: true,
        defaultMode: LOGIN_MODE,
        isConnectionCancelable: cancelable,
        currentConnectionProcessCallback: async (customer: ?Customer) =>
          Promise.resolve(resolve(customer || null)),
      })
    })
  }

  register = async (
    firstName: string,
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> => {
    const { programId } = this.props
    const { customerContextApi } = this.state

    return customerContextApi.registerCustomer(
      programId,
      firstName,
      email,
      password,
    )
  }

  logIn = async (
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> => {
    const { customerContextApi } = this.state

    return customerContextApi.logInCustomer(email, password)
  }

  onConnectionSuccess = async (customer: Customer): Promise<void> => {
    const { customerContextApi, currentConnectionProcessCallback } = this.state

    this.setState(
      { customerContextApi: customerContextApi.replaceCustomer(customer) },
      async () => {
        if (currentConnectionProcessCallback) {
          await currentConnectionProcessCallback(customer)
        }

        this.setState({
          isConnectionModalOpen: false,
          currentConnectionProcessCallback: null,
        })
      },
    )
  }

  onConnectionCanceled = async (): Promise<void> => {
    const { currentConnectionProcessCallback } = this.state

    if (currentConnectionProcessCallback) {
      await currentConnectionProcessCallback(null)
    }

    return new Promise(resolve => {
      this.setState(
        {
          isConnectionModalOpen: false,
          currentConnectionProcessCallback: null,
        },
        () => resolve(),
      )
    })
  }

  requestNewPassword = async (email: string) => {
    const { httpClient, programId } = this.props
    const { shoppingCart } = this.state
    if (shoppingCart) {
      writeShoppingCart(shoppingCart)
    }
    return requestNewPassword(httpClient, programId, email)
  }

  logOutCustormer = async (): Promise<void> => {
    const { customerContextApi } = this.state

    deleteToken()
    this.setState({
      customerContextApi: customerContextApi.replaceCustomer(null),
    })
  }

  onRequestNewPasswordExitedAfterSuccess = async () => {
    await this.onConnectionCanceled()

    history.push(programPreviewHomePageRoute())
  }

  render() {
    const {
      children,
      privacyPoliciesPageUrl,
      termsOfServicesPageUrl,
    } = this.props
    const {
      initialisingAuthentication,
      customerContextApi,
      isConnectionModalOpen,
      defaultMode,
      isConnectionCancelable,
    } = this.state

    if (initialisingAuthentication) {
      return <LoadingScreen isVisible />
    }

    return (
      <React.Fragment>
        <CustomerContext.Provider value={customerContextApi}>
          {children}
        </CustomerContext.Provider>

        {process.env.REACT_APP_DISABLE_CLIENT_AREA !== 'true' && (
          <CustomerConnectionModal
            isOpen={isConnectionModalOpen}
            defaultMode={defaultMode}
            closable={isConnectionCancelable}
            doRegistration={this.register}
            doLogIn={this.logIn}
            doRequestNewPassword={this.requestNewPassword}
            onConnectionSuccess={this.onConnectionSuccess}
            onClose={this.onConnectionCanceled}
            onRequestNewPasswordExitedAfterSuccess={
              this.onRequestNewPasswordExitedAfterSuccess
            }
            privacyPoliciesPageUrl={privacyPoliciesPageUrl}
            termsOfServicesPageUrl={termsOfServicesPageUrl}
          />
        )}
      </React.Fragment>
    )
  }
}

export default (CustomerAreaBridge: React.ComponentType<Props>)
