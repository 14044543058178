// @flow

import * as React from 'react'

export default (): React.Element<'svg'> => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.228 0C17.692.05 20 2.549 20 10.54 20 17.647 17.53 20 10 20 2.52 20 .07 17.66.002 10.327L0 10v-.226C.049 2.473 2.415.072 9.668.002L10 0h.228zM10 1.668l-.394.002h-.097l-.096.002-.376.009-.184.005-.36.015-.347.02-.337.024-.325.03c-3.75.373-5.257 1.801-5.682 5.446l-.028.264-.03.325-.024.337-.02.348-.015.359-.005.184-.009.376-.001.096-.001.097-.002.394.002.394v.097l.002.096.009.376.005.184.015.36.02.347.024.337.03.325c.373 3.75 1.801 5.257 5.446 5.682l.264.028.325.03.337.024.348.02.359.015.184.005.376.009.096.001.097.001.394.002.392-.002.191-.002.189-.004.368-.01.09-.003.09-.004.352-.016.256-.016.25-.018.325-.028.08-.008.078-.008.308-.036c3.238-.408 4.676-1.69 5.161-4.617l.04-.255.037-.287.017-.148.016-.15.027-.307.006-.078.006-.08.02-.322.015-.333.01-.343.007-.354.002-.365-.002-.423-.002-.207-.004-.204-.01-.398-.003-.097-.004-.097-.017-.38-.021-.366-.006-.09-.007-.089-.029-.348c-.394-4.307-1.988-5.807-6.262-6.111l-.276-.018-.355-.018-.367-.013-.187-.005-.385-.006L10 1.667zm0 11.25a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5zm0-8.334c1.59 0 2.87 1.268 2.87 2.85 0 .957-.448 1.712-1.234 2.365l-.1.081-.058.042-.142.096c-.37.258-.47.407-.495.735l-.006.125a5.54 5.54 0 0 0-.002.142.833.833 0 1 1-1.666 0c0-1.064.267-1.714 1.288-2.418l.066-.045.068-.055c.396-.333.588-.641.612-.992l.003-.076c0-.658-.53-1.183-1.204-1.183-.65 0-1.166.488-1.202 1.113l-.002.07a.833.833 0 0 1-1.666 0c0-1.582 1.28-2.85 2.87-2.85z"
      fill="#FFF"
      fillOpacity="0.85"
      fillRule="nonzero"
    />
  </svg>
)
