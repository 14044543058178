// @flow
declare var _paq: ?Array<Array<any>>

function isDefined<T>(x: T | void | null): boolean {
  return x !== null && typeof x !== 'undefined'
}

export const Matomo = {
  trackEvent(category: string, action?: string, name?: string, value?: any) {
    _paq?.push(['trackEvent', category, action, name, value].filter(isDefined))
  },
  trackPageView(name: string) {
    _paq?.push(['trackPageView', name])
  },
  setCustomUrl(url: string) {
    _paq?.push(['setCustomUrl', url])
  },
}
