// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoBold } from '../Typography/Fonts'

export const IconInTheButton: any = styled.span`
  display: block;
  width: 16px;
  height: 16px;
`

const LeftIcon = styled(IconInTheButton)`
  margin-right: 4px;
`

export const TextInTheButton: any = styled.span`
  display: block;
  font-family: ${RobotoBold.fontFamily};
  font-size: 11px;
  font-weight: ${RobotoBold.weight};
  text-transform: uppercase;
  line-height: 20px;
  vertical-align: middle;
  letter-spacing: 1px;
  white-space: nowrap;
`

const StyledBaseButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  height: 40px;
  padding: 0 14px;
  border: 2px solid;
  border-radius: 20px;

  backdrop-filter: blur(1px);

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }
`

export type BaseButtonProps = {|
  forwardAs?: 'input',
  children: React.Node,
  leftIcon?: React.Node,
  fluid?: boolean,
  value?: any,
  forceDark?: boolean,
|}

export const BaseButton = ({
  children,
  leftIcon,
  ...otherProps
}: BaseButtonProps): React.Node => (
  <StyledBaseButton {...otherProps}>
    {leftIcon ? <LeftIcon>{leftIcon}</LeftIcon> : null}
    <TextInTheButton>{children}</TextInTheButton>
  </StyledBaseButton>
)
