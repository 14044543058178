// @flow

import * as React from 'react'
import { Colors } from '../Colors'

export default (): React.Element<'svg'> => (
  <svg
    width="128"
    height="110"
    viewBox="0 0 128 110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M71 56v4H11a6 6 0 0 0-5.996 5.775L5 66v32a6 6 0 0 0 5.775 5.996L11 104l60.382.001a10.47 10.47 0 0 0 2.178 4L11 108c-5.523 0-10-4.477-10-10V66c0-5.523 4.477-10 10-10h60z"
        fillOpacity=".1"
        fill={process.env.REACT_APP_LIGHT_UI === 'true' ? '#000' : '#fff'}
      />
      <path
        d="M127 98c0 5.523-4.477 10-10 10H85c-5.523 0-10-4.477-10-10V12c0-5.523 4.477-10 10-10h32c5.523 0 10 4.477 10 10v86zm-4 0V12a6 6 0 0 0-6-6H85a6 6 0 0 0-6 6v86a6 6 0 0 0 6 6h32a6 6 0 0 0 6-6z"
        fill={Colors.brand}
      />
      <path
        d="m60.55 20.165.1.076 7 6 .015.012c.024.022.047.044.069.068l-.083-.08a1.009 1.009 0 0 1 .318.513c.02.078.031.16.031.246l-.002.061a1.003 1.003 0 0 1-.004.05L68 27a1.007 1.007 0 0 1-.233.642l-.008.009-6 7a1 1 0 0 1-1.594-1.201l.076-.1 4.52-5.277C53.494 28.828 47 35.435 47 48a1 1 0 0 1-2 0c0-13.588 7.258-21.002 19.405-21.906l-5.056-4.335a1 1 0 0 1-.184-1.31l.076-.1a1 1 0 0 1 1.31-.184z"
        stroke={Colors.brand}
        fill={Colors.brand}
        fillRule="nonzero"
      />
    </g>
  </svg>
)
