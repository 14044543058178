// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Input, CheckboxInput } from '.'
import { Colors } from '../Colors'
import CheckboxLabel from './CheckboxLabel'
import FormattedInputBase from 'react-number-format'
import { RobotoMedium } from '../Typography/Fonts'

const TextInputFieldContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid
    ${({ linkedInputIsFocus, disabled }) =>
      linkedInputIsFocus
        ? Colors.brand
        : disabled
        ? 'none'
        : process.env.REACT_APP_LIGHT_UI === 'true'
        ? Colors.black10
        : Colors.white10};
  transition: border-color 250ms ease-in-out;
  padding: 7px 11px;

  &:hover {
    border: 1px solid
      ${({ linkedInputIsFocus, disabled }) =>
        linkedInputIsFocus
          ? Colors.brand
          : disabled
          ? 'none'
          : process.env.REACT_APP_LIGHT_UI === 'true'
          ? Colors.black30
          : Colors.white30};
  }
`

const Label = styled.div`
  margin-bottom: 2px;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.weight};
  line-height: 14px;
  font-size: 12px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black60
    : Colors.white60};
`

const CurrencyInput = styled(FormattedInputBase).attrs({
  thousandSeparator: ' ',
  decimalScale: 0,
  allowNegative: false,
  placeholder: ' €',
  suffix: ' €',
  customInput: Input,
})``

const AreaInput = styled(FormattedInputBase).attrs({
  thousandSeparator: ' ',
  decimalScale: 0,
  allowNegative: false,
  placeholder: ' m²',
  suffix: ' m²',
  customInput: Input,
})``

type TextInputFieldProps = {
  label: React.Node,
  errorMessage?: ?string,
  onFocus?: FocusEventHandler,
  onBlur?: FocusEventHandler,
  labelOnFocus?: string,
  disabled?: boolean,
  inputType?: string,
}

export const TextInputField: React.ComponentType<any> = React.forwardRef<
  TextInputFieldProps,
  typeof Input,
>(
  (
    {
      label,
      errorMessage,
      onFocus,
      onBlur,
      labelOnFocus,
      disabled,
      inputType,
      ...props
    },
    ref,
  ) => {
    const [linkedInputIsFocus, setLinkedInputIsFocus] = React.useState(false)

    return (
      <TextInputFieldContainer
        linkedInputIsFocus={linkedInputIsFocus}
        disabled={disabled}
      >
        <Label>{label}</Label>
        {(() => {
          switch (inputType) {
            case 'currency':
              return (
                <CurrencyInput
                  {...props}
                  ref={ref}
                  disabled={disabled}
                  onFocus={(...args) => {
                    setLinkedInputIsFocus(true)

                    if (onFocus) {
                      onFocus(...args)
                    }
                  }}
                  onBlur={(...args) => {
                    setLinkedInputIsFocus(false)

                    if (onBlur) {
                      onBlur(...args)
                    }
                  }}
                />
              )
            case 'area':
              return (
                <AreaInput
                  {...props}
                  ref={ref}
                  disabled={disabled}
                  onFocus={(...args) => {
                    setLinkedInputIsFocus(true)

                    if (onFocus) {
                      onFocus(...args)
                    }
                  }}
                  onBlur={(...args) => {
                    setLinkedInputIsFocus(false)

                    if (onBlur) {
                      onBlur(...args)
                    }
                  }}
                />
              )
            default:
              return (
                <Input
                  {...props}
                  ref={ref}
                  disabled={disabled}
                  onFocus={(...args) => {
                    setLinkedInputIsFocus(true)

                    if (onFocus) {
                      onFocus(...args)
                    }
                  }}
                  onBlur={(...args) => {
                    setLinkedInputIsFocus(false)

                    if (onBlur) {
                      onBlur(...args)
                    }
                  }}
                />
              )
          }
        })()}
      </TextInputFieldContainer>
    )
  },
)

const CheckboxInputFieldContainer = styled.div`
  border-bottom: 1px solid
    ${process.env.REACT_APP_LIGHT_UI === 'true'
      ? Colors.black10
      : Colors.white10};
  transition: border-color 250ms ease-in-out;
`

type CheckboxInputFieldProps = {
  label: React.Node,
  errorMessage?: ?string,
}

export const CheckboxInputField: React.ComponentType<any> = React.forwardRef<
  CheckboxInputFieldProps,
  typeof CheckboxInput,
>(({ label, errorMessage, ...props }, ref) => {
  return (
    <CheckboxInputFieldContainer>
      <CheckboxLabel label={label} errorMessage={errorMessage}>
        <CheckboxInput {...props} ref={ref} />
      </CheckboxLabel>
    </CheckboxInputFieldContainer>
  )
})
