// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './../Colors'
import { BaseButton, type BaseButtonProps } from './BaseButton'

const StyledFilledButton = styled(BaseButton)`
  color: ${Colors.black85};
  border-color: transparent;
  background-color: ${Colors.ghostWhite};
  box-shadow: 0 0 16px 0 ${Colors.black85};
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:hover {
    border-color: transparent;
    background-color: ${Colors.brightGray};
    box-shadow: 0 0 4px 0 ${Colors.black85};
  }

  &:disabled {
    color: ${Colors.black35};
    border-color: transparent;
    background-color: ${Colors.white10};
    pointer-events: none;
  }
`

export const FilledButton = ({
  children,
  ...otherProps
}: {
  ...BaseButtonProps,
}): React.Node => (
  <StyledFilledButton {...otherProps}>{children}</StyledFilledButton>
)
