// @flow

import Customer from '../CustomerArea/Customer'
import ConfigurationShoppingCart from '../FlatConfigurator/MainMenu/Configuration/ShoppingCart/ConfigurationShoppingCart'
import {
  type ConfigurationsPayload,
  type ConfigurationPayload,
} from '../CustomerArea/api'

export interface CustomerContextApi {
  customer: ?Customer;

  replaceCustomer(newCustomer: ?Customer): CustomerContextApi;

  isAuthenticated(): boolean;

  startRegistrationProcess(
    shoppingCart?: ConfigurationShoppingCart,
  ): Promise<?Customer>;

  startLoginProcess(cancelable?: boolean): Promise<?Customer>;

  registerCustomer(
    programId: string,
    firstName: string,
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }>;

  logInCustomer(
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }>;

  logOutCustomer(): any;

  saveShoppingCart(shoppingCart: ConfigurationShoppingCart): Promise<string>;

  fetchShoppingCartByLotId(lotId: string): Promise<?ConfigurationShoppingCart>;

  fetchConfigurations(): Promise<ConfigurationsPayload>;

  fetchConfiguration(configurationId: string): Promise<ConfigurationPayload>;

  deleteConfiguration(configurationId: string): Promise<any>;

  refreshCustomer(): Promise<Customer>;

  configurationCustomerContact(
    configurationId: string,
    firstName: string,
    lastName: string,
    phone: string,
  ): Promise<Array<{| field: ?string, message: string |}>>;

  editProfilCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  ): Promise<Array<{| field: ?string, message: string |}>>;

  editPasswordCustomer(
    oldPassword: string,
    newPassword: string,
  ): Promise<Array<{| field: ?string, message: string |}>>;
}
