// @flow

import ExtendableError from 'es6-error'

export const MISSING_TOKEN = 'MISSING_TOKEN'

export default class CustomerAuthenticationMissing extends ExtendableError {
  +code: string

  constructor() {
    super('The authentication token is missing')

    this.code = MISSING_TOKEN
  }
}
