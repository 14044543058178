// @flow
import * as React from 'react'
import styled from 'styled-components'
import history from '../history'
import { programPreviewHomePageRoute } from '../urls'
import { PrimaryButton } from './Button/index'
import PageNotFoundSvg from './Icons/PageNotFoundSvg'
import { Colors } from './Colors'
import { RobotoMedium } from './Typography/Fonts'
import { useTranslation } from 'react-i18next'
import FooterCusthome from './FooterCusthome'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.ghostWhite
    : Colors.darkCharcoal};
`

const TextContainer = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.weight};
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 256px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black85
    : Colors.white85};
`

export default (): React.Node => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <PageNotFoundSvg />
      <TextContainer>{t('pageNotFound')}</TextContainer>
      <PrimaryButton
        onClick={() => history.push(programPreviewHomePageRoute())}
      >
        Accueil
      </PrimaryButton>
      <FooterCusthome />
    </Wrapper>
  )
}
