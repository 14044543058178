// @flow

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Customer from './CustomerArea/Customer'

export const initErrorReportingAgent = (
  dsn: string,
  buildEnvironment: string,
) => {
  Sentry.init({
    dsn,
    environment: buildEnvironment,
    integrations: [new Integrations.ExtraErrorData()],
  })
}

export const attachCustomerToContext = (customer: ?Customer) => {
  Sentry.configureScope(scope => {
    if (!customer) {
      scope.setUser({
        id: null,
        username: null,
        email: null,
      })
    } else {
      scope.setUser({
        id: customer.id,
        username: `${customer.firstName} ${customer.lastName || ''}`.trim(),
        email: customer.email,
      })
    }
  })
}
