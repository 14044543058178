// @flow

import ExtendableError from 'es6-error'

export const EXPIRED_TOKEN = 'EXPIRED_TOKEN'

export default class CustomerAuthenticationExpired extends ExtendableError {
  +code: string

  constructor() {
    super('The authentication token has expired')

    this.code = EXPIRED_TOKEN
  }
}
