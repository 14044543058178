// @flow

import styled from 'styled-components'
import { BaseModalBackground } from 'styled-react-modal'
import { Colors } from '../Colors'
import { device } from '../Devices'

const preventMouseMoveEventPropagation = (
  originalOnMouseMoveHandler = null,
) => (e: SyntheticEvent<HTMLDivElement>) => {
  e.stopPropagation()

  if (originalOnMouseMoveHandler) {
    originalOnMouseMoveHandler(e)
  }
}

const BluredModalBackground: any = styled(BaseModalBackground).attrs(props => {
  if (props.onMouseMove !== preventMouseMoveEventPropagation) {
    return {
      onMouseMove: preventMouseMoveEventPropagation(props.onMouseMove),
    }
  }

  return {}
})`
  height: 100%;
  background-color: ${({ fullScreen, noBackground }) =>
    fullScreen || noBackground ? 'transparent' : Colors.black85};
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.opacity};

  .highlight & {
    background: transparent;
    backdrop-filter: none;
    z-index: 99;
  }

  @media ${device.laptop} {
    transition: opacity 0.3s ease-in-out;
  }
`

export default BluredModalBackground
